import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./bannerComponent.css";
import "./modal.css";
import { useCookies } from "react-cookie";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";

export const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#00214b",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};
export const sxModal = {
  mt: 2,
  "& .MuiInputBase-root": {
    color: "white", // Cambia el color del texto a blanco
  },
  "& .MuiOutlinedInput-root": {
    borderColor: "white", // Cambia el color del borde a blanco
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "white", // Asegura que el borde esté blanco en el estado normal
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "white", // Borde blanco al pasar el ratón
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "white", // Borde blanco al estar enfocado
  },
  "& .MuiFormLabel-root": {
    color: "#0075a9", // Cambia el color de la etiqueta (label) a blanco
  },
  "& .MuiFormHelperText-root": {
    color: "white", // Cambia el color del texto de ayuda (helperText) a blanco
  },
}

const BannerComponent = ({
  bannerTitle,
  bannerContent,
  bannerLink,
  type,
  articleSlug,
}) => {
  const [cookies, setCookie] = useCookies(["cookieMail"]);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(false); // Limpiar el error cuando se escribe en el campo
  };
  const handleOpen = () => setOpen(true);
  const handleCloseSave = () => {
    setCookie("cookieMail", "true");
    setOpen(false);
  };

  const getFileNameFromUrl = (url) => {
    const parts = url.split("/");
    return parts[parts.length - 1]; // El último segmento es el nombre del archivo
  };
  const handleClose = () => setOpen(false);

  // Función para validar el email y realizar el fetch
  const handleSubmit = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
      setEmailHelperText("Por favor, ingresa un correo electrónico válido.");
    } else {
      // Realizamos el fetch para validar el email
      // Crear una nueva instancia de FormData
      const formData = new FormData();
      formData.append("email", email);
      try {
        const response = await fetch(
          "https://propuestas.chimpance.digital/api/api/mail/blog",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
          }
        );

        if (response.ok) {
          handleCloseSave(); // Cerrar el modal después de enviar
          const fileName = getFileNameFromUrl(bannerLink);
          // Crear un enlace para la descarga
          const link = document.createElement("a");
          link.href = bannerLink;
          link.download = fileName; // Cambiar el nombre del archivo si es necesario

          // Abrir una nueva ventana para realizar la descarga
          const newWindow = window.open("", "_blank");
          if (newWindow) {
            // Escribir el enlace en la nueva ventana y hacer clic en él
            newWindow.document.body.appendChild(link); // Agregar el enlace al cuerpo de la nueva ventana
            link.click(); // Hacer clic programáticamente
            newWindow.document.body.removeChild(link); // Eliminar el enlace después de la descarga
          } else {
            // Si la nueva ventana no se puede abrir (bloqueo del navegador), hacer la descarga en la ventana actual
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Eliminar el enlace del DOM
          }
        }
      } catch (error) {
        // Manejo de errores en caso de que falle la petición
        console.error("Error en la validación del email:", error);
      }
    }
  };

  return (
    <>
      {type === "download" ? (
        <div className="article__bannerDescarga">
          <img
            className="article__bannerDescarga__campana"
            src="/images/bannerBell.svg"
            alt="campana"
          />
          <p className="article__bannerDescarga__titulo">{bannerTitle}</p>
          {cookies.cookieMail ? (
            <a
              className="article__bannerDescarga__enlace"
              download
              href={bannerLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              Descargar{" "}
              <svg
                className="article__bannerDescarga__enlace__icono"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M16.59 9H15V4c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1v5H7.41c-.89 0-1.34 1.08-.71 1.71l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.63-.63.19-1.71-.7-1.71M5 19c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1 .45-1 1"
                />
              </svg>
            </a>
          ) : (
            <a className="article__bannerDescarga__enlace" onClick={handleOpen}>
              Descargar{" "}
              <svg
                className="article__bannerDescarga__enlace__icono"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M16.59 9H15V4c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1v5H7.41c-.89 0-1.34 1.08-.71 1.71l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.63-.63.19-1.71-.7-1.71M5 19c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1 .45-1 1"
                />
              </svg>
            </a>
          )}

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModal}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Ingresa tu mail para descargar el contenido.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Esto solo sera necesario la primera vez, muchas gracias
              </Typography>
              {/* Campo de input con validación de email */}
              <TextField
                fullWidth
                label="Correo Electrónico"
                variant="outlined"
                type="email"
                value={email}
                onChange={handleEmailChange}
                error={emailError}
                helperText={emailError ? emailHelperText : ""}
                sx={sxModal}
              />

              <a
                className="article__bannerDescarga__enlace mt-4"
                onClick={handleSubmit}
              >
                Descargar{" "}
                <svg
                  className="article__bannerDescarga__enlace__icono"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M16.59 9H15V4c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1v5H7.41c-.89 0-1.34 1.08-.71 1.71l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.63-.63.19-1.71-.7-1.71M5 19c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1 .45-1 1"
                  />
                </svg>
              </a>
            </Box>
          </Modal>
        </div>
      ) : (
        <div className="article__BannerEnlace">
          <div className="article__BannerEnlace__contenido">
            <p className="article__BannerEnlace__contenido__titulo">
              {bannerTitle}
            </p>
            <div className="article__BannerEnlace__contenido__texto">
              {bannerContent}
            </div>
          </div>
          <a className="article__BannerEnlace__enlace" href={bannerLink}>
            <img
              className="article__BannerEnlace__avion"
              src="/images/bannerPlane.svg"
              alt="avion"
            />
            ¡Quiero saber más!
          </a>
        </div>
      )}
    </>
  );
};

export default BannerComponent;
