import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import Header from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import ArticleDetail from "../../components/blog/Article";
import {
  fetchArticleBySlug,
  fetchArticles,
  fetchComments,
  submitComment,
} from "../../services/strapiServices";
import Articles from "../../components/blog/Articles";
import Comments from "../../components/blog/Article/Comments";
import CommentForm from "../../components/blog/Article/CommentForm";
import "./../../components/blog/Article/article2.css";
import MobileNavbar from "../../layouts/MobileNavbar";
import Loading from "../../components/Loading";
import NotFound from "../NotFound";
import { useCookies } from "react-cookie";
import { Modal, Box, Typography, TextField } from "@mui/material";
import {
  styleModal,
  sxModal,
} from "../../components/blog/Article/bannerComponent";

export default function ArticlePage({ isMobile }) {
  const { slug } = useParams();
  const [cookies, setCookie] = useCookies(["cookieMail"]);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [suscripcionOk, setSuscripcionOk] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");

  const [article, setArticle] = useState(null);
  const [articles, setArticles] = useState(null);
  const [error, setError] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [comments, setComments] = useState({});
  const [sended, setSended] = useState(false);
  const [commentData, setCommentData] = useState({
    content: "",
    autor: "",
    autorEmail: "",
  });
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(false); // Limpiar el error cuando se escribe en el campo
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseSave = () => {
    setCookie("cookieMail", "true");
    setOpen(false);
  };

  // Función para validar el email y realizar el fetch
  const handleSubscribe = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
      setEmailHelperText("Por favor, ingresa un correo electrónico válido.");
    } else {
      setLoading(true);
      // Realizamos el fetch para validar el email
      // Crear una nueva instancia de FormData
      const formData = new FormData();
      formData.append("email", email);
      try {
        const response = await fetch(
          "https://propuestas.chimpance.digital/api/api/mail/blog",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
          }
        );

        if (response.ok) {
          setLoading(false);
          setSuscripcionOk(true);
        }
      } catch (error) {
        setLoading(false);
        // Manejo de errores en caso de que falle la petición
        console.error("Error en la validación del email:", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      submitComment(commentData, article.id);
      setCommentData({
        content: "",
        autor: "",
        autorEmail: "",
      });
      setSended(true);
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  useEffect(() => {
    const getArticlePage = async () => {
      if (slug) {
        try {
          const result = await fetchArticleBySlug(slug);
          console.log(result);
          if (result.error) {
            setError(result.error);
            return;
          }
          setArticle(result.data);
          const relatedResult = await fetchArticles(
            "",
            [result.data.category],
            1,
            3
          );
          if (relatedResult.error) {
            setError(relatedResult.error);
            console.error(error);
          } else {
            setArticles(relatedResult.data.data);
          }
          const commentResult = await fetchComments(result.data.id);
          if (commentResult.error) {
            setError(commentResult.error);
            console.error(error);
          } else {
            setComments(commentResult.comments);
          }
        } catch (error) {
          setError(error.message);
          console.error(error);
        }
      }
    };

    getArticlePage();
  }, [slug]);
  if (error) {
    return <NotFound isMobile={isMobile} />;
  }

  if (!article) {
    return <Loading />;
  }

  return (
    <div className="ArticlePage">
      <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article.lead} />
        <meta
          name="keywords"
          content="Desarrollo Web, Marketing Digital, Branding, Programacion"
        />
      </Helmet>
      <Header
        isMobile={isMobile}
        menuIsOpen={menuIsOpen}
        setMenuIsOpen={setMenuIsOpen}
      />
      {/* Renderiza componentes para mobile */}
      {isMobile && (
        <MobileNavbar menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
      )}

      <ArticleDetail article={article} />

      <div className="article__subscribeAdd container">
        <p className="article__subscribeAdd__text">
          ¿Te gustaría recibir nuestro contenido de forma personalizada?
        </p>
        <a className="article__subscribeAdd__enlace" onClick={handleOpen}>
          <img
            className="article__subscribeAdd__cohete"
            src="/images/rocket.svg"
            alt="avion"
          />
          Suscribite
        </a>
        <span className="relatedPostTitle">Post relacionados</span>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            ¡Sé el primero en enterarte!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Dejanos tu email y enterate de todas las novedades, promociones
            exclusivas y contenido que tenemos para vos. No te lo podés perder,
            te mandamos todo directamente a tu casilla. ¡Es rápido y fácil!
          </Typography>
          {/* Campo de input con validación de email */}
          <TextField
            fullWidth
            label="Correo Electrónico"
            variant="outlined"
            type="email"
            value={email}
            onChange={handleEmailChange}
            error={emailError}
            helperText={emailError ? emailHelperText : ""}
            sx={sxModal}
          />

          {suscripcionOk ? (
            <Fragment>
              <a
                className="article__subscribeAdd__enlace"
                onClick={handleCloseSave}
              >
                <img
                  className="article__subscribeAdd__cohete"
                  src="/images/rocket.svg"
                  alt="avion"
                />
                Cerrar
              </a>
              <Typography sx={{ mt: 2 }}>Gracias por suscribirte</Typography>
            </Fragment>
          ) : (
            <button
              className="article__subscribeAdd__enlace"
              onClick={handleSubscribe}
              disabled={loading}
            >
              <img
                className="article__subscribeAdd__cohete"
                src="/images/rocket.svg"
                alt="avion"
              />
              {loading ? "Enviando.." : "Suscribirse"}
            </button>
          )}
        </Box>
      </Modal>

      <Articles articles={articles} />

      <Comments comments={comments} />
      <CommentForm
        commentData={commentData}
        sended={sended}
        setCommentData={setCommentData}
        handleSubmit={handleSubmit}
      />
      <Footer color="#00214B" />
    </div>
  );
}
