import React from 'react'
import "./loading.css"

export default function Loading({height = "100dvh"}) {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{height}}>
      <div class="contenido d-flex justify-content-center flex-column align-items-center">

        <svg width="188" height="34" viewBox="0 0 188 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Frame" clip-path="url(#clip0_771_864)">
            <path id="ojo1" d="M89.4004 17.4699C89.4004 16.5226 89.6813 15.5965 90.2077 14.8087C90.734 14.021 91.4821 13.4071 92.3573 13.0446C93.2326 12.682 94.1957 12.5871 95.1249 12.772C96.054 12.9568 96.9075 13.413 97.5774 14.0829C98.2473 14.7528 98.7035 15.6063 98.8884 16.5354C99.0732 17.4646 98.9783 18.4277 98.6158 19.303C98.2532 20.1782 97.6393 20.9263 96.8516 21.4527C96.0639 21.979 95.1378 22.2599 94.1904 22.2599C92.92 22.2599 91.7017 21.7553 90.8034 20.857C89.9051 19.9587 89.4004 18.7403 89.4004 17.4699Z" fill="#ffffff" />
            <path id="ojo2" d="M111.779 17.4699C111.779 16.5621 112.049 15.6746 112.553 14.9198C113.057 14.165 113.774 13.5767 114.613 13.2293C115.451 12.8819 116.374 12.791 117.265 12.9681C118.155 13.1452 118.973 13.5823 119.615 14.2243C120.257 14.8662 120.694 15.684 120.871 16.5744C121.048 17.4648 120.957 18.3877 120.61 19.2264C120.263 20.0651 119.674 20.782 118.919 21.2863C118.165 21.7907 117.277 22.0599 116.369 22.0599C115.152 22.0599 113.984 21.5763 113.124 20.7155C112.263 19.8547 111.779 18.6872 111.779 17.4699Z" fill="#ffffff" />
            <path id="Vector_3" d="M2.52964 13.8199C2.52493 15.2803 2.81753 16.7263 3.38964 18.0699C4.51994 20.7054 6.62221 22.8041 9.25964 23.9299C10.6008 24.5109 12.048 24.8072 13.5096 24.7999C14.9616 24.808 16.3992 24.5116 17.7296 23.9299C19.0232 23.3706 20.2002 22.5735 21.1996 21.5799C21.6357 21.1447 22.0338 20.673 22.3896 20.1699H25.3896C24.776 21.3463 23.9908 22.4248 23.0596 23.3699C21.8244 24.6046 20.365 25.5922 18.7596 26.2799C17.1029 26.9793 15.3229 27.3397 13.5246 27.3397C11.7264 27.3397 9.94632 26.9793 8.28963 26.2799C5.04896 24.896 2.46902 22.3125 1.08963 19.0699C0.383533 17.415 0.0195312 15.6342 0.0195312 13.8349C0.0195312 12.0356 0.383533 10.2549 1.08963 8.59993C2.47376 5.36288 5.05258 2.78406 8.28963 1.39993C9.94461 0.693832 11.7253 0.329834 13.5246 0.329834C15.3239 0.329834 17.1047 0.693832 18.7596 1.39993C20.365 2.08762 21.8244 3.07528 23.0596 4.30994C23.9983 5.24209 24.7906 6.31084 25.4096 7.47993H22.4096C22.0704 6.99724 21.6853 6.54849 21.2596 6.13994C20.2681 5.12167 19.09 4.30349 17.7896 3.72993C16.4592 3.14824 15.0216 2.85186 13.5696 2.85992C12.108 2.85267 10.6608 3.14893 9.31963 3.72993C6.68571 4.86092 4.58511 6.95794 3.44963 9.58994C2.8756 10.9259 2.58285 12.3658 2.58963 13.8199" fill="#ffffff" />
            <path id="Vector_4" d="M27.9902 1.19995H30.5902V10.0099C30.8747 9.61643 31.211 9.26322 31.5902 8.95995C31.999 8.64238 32.4458 8.37701 32.9202 8.16995C33.412 7.94935 33.9245 7.77849 34.4502 7.65994C34.9719 7.54089 35.5052 7.48052 36.0402 7.47995C37.1239 7.4763 38.1965 7.69763 39.1902 8.12994C40.1629 8.56541 41.0475 9.1755 41.8002 9.92995C42.5396 10.6769 43.133 11.5553 43.5502 12.5199C43.9837 13.5098 44.2051 14.5794 44.2002 15.6599V26.5H41.6002V15.6599C41.6026 14.9152 41.4564 14.1775 41.1702 13.4899C40.8911 12.8323 40.4831 12.2373 39.9702 11.7399C39.4582 11.2453 38.8623 10.8458 38.2102 10.56C37.5323 10.2766 36.805 10.1305 36.0702 10.1299C35.3539 10.1308 34.6467 10.2914 34.0002 10.5999C33.3499 10.9088 32.7605 11.3323 32.2602 11.8499C31.7653 12.3508 31.3595 12.9325 31.0602 13.5699C30.7641 14.2199 30.6107 14.9257 30.6102 15.64V26.4799H27.9902V1.19995Z" fill="#ffffff" />
            <path id="Vector_5" d="M46.6197 2.71998C46.629 2.38176 46.7548 2.05711 46.9757 1.80082C47.1966 1.54453 47.4991 1.37228 47.8322 1.31315C48.1654 1.25402 48.5087 1.31163 48.8043 1.47625C49.0999 1.64087 49.3297 1.90242 49.4549 2.21678C49.58 2.53113 49.5929 2.87903 49.4914 3.20178C49.3898 3.52453 49.18 3.80236 48.8974 3.9884C48.6148 4.17443 48.2766 4.25728 47.94 4.22296C47.6034 4.18863 47.289 4.03923 47.0497 3.79998C46.9084 3.65865 46.7973 3.48999 46.7234 3.3043C46.6495 3.11861 46.6142 2.91977 46.6197 2.71998ZM46.8297 8.41999H49.3597V26.48H46.8297V8.41999Z" fill="#ffffff" />
            <path id="Vector_6" d="M51.9609 8.42013H54.5609V10.0501C55.1857 9.2444 55.9998 8.60547 56.9309 8.19012C58.0352 7.66784 59.2505 7.42395 60.4707 7.47973C61.691 7.53551 62.879 7.88928 63.9309 8.51012C65.0664 9.19721 66.0335 10.1301 66.7609 11.2401C67.4952 10.0969 68.5065 9.15777 69.7009 8.51012C70.9272 7.84113 72.3041 7.49689 73.7009 7.51012C74.7815 7.50523 75.8511 7.72664 76.8409 8.16012C77.8035 8.58113 78.6812 9.17417 79.4309 9.91012C80.1675 10.659 80.7546 11.5415 81.1609 12.5101C81.5804 13.5106 81.7947 14.5852 81.7909 15.6701V26.5101H79.1909V15.6701C79.1981 14.9282 79.0517 14.1928 78.7609 13.5101C78.4856 12.8494 78.0849 12.2484 77.5809 11.7401C77.0891 11.2299 76.5011 10.822 75.8509 10.5401C75.1697 10.2563 74.439 10.1101 73.7009 10.1101C72.9629 10.1101 72.2322 10.2563 71.5509 10.5401C70.8975 10.8269 70.3039 11.2339 69.8009 11.7401C69.2846 12.2556 68.8755 12.8684 68.5975 13.5429C68.3194 14.2175 68.1778 14.9405 68.1809 15.6701V26.5101H65.5809V15.6701C65.5351 14.9049 65.3558 14.1535 65.0509 13.4501C64.7647 12.7929 64.3615 12.1932 63.8609 11.6801C62.8478 10.6747 61.4783 10.1103 60.0509 10.1101C59.3214 10.1078 58.6005 10.2683 57.9409 10.5801C57.2858 10.8859 56.6926 11.3096 56.1909 11.8301C55.6821 12.3385 55.2748 12.9392 54.9909 13.6001C54.709 14.2539 54.5627 14.9581 54.5609 15.6701V26.5101H51.9609V8.42013Z" fill="#ffffff" />
            <path id="Vector_7" d="M84.2798 17.4499C84.2705 16.1232 84.5361 14.8089 85.0598 13.5899C85.5606 12.4098 86.2838 11.337 87.1898 10.4299C88.0794 9.3886 89.1748 8.54257 90.4071 7.94501C91.6394 7.34744 92.982 7.01126 94.3505 6.95758C95.7191 6.90391 97.0839 7.13389 98.3592 7.6331C99.6346 8.13231 100.793 8.88995 101.761 9.85838C102.73 10.8268 103.487 11.9851 103.987 13.2604C104.486 14.5358 104.716 15.9006 104.662 17.2691C104.608 18.6376 104.272 19.9803 103.675 21.2126C103.077 22.4449 102.231 23.5403 101.19 24.4299C100.273 25.3528 99.182 26.0851 97.9806 26.5845C96.7792 27.084 95.4908 27.3407 94.1898 27.3399C92.7159 27.3121 91.2697 26.9351 89.9698 26.2399C88.6772 25.6423 87.5762 24.6971 86.7898 23.5099V33.6199H84.2598L84.2798 17.4499ZM86.8098 17.4499C86.8031 18.4363 87.0005 19.4135 87.3898 20.3199C87.7492 21.2157 88.2837 22.0309 88.9619 22.7177C89.6401 23.4046 90.4485 23.9492 91.3398 24.3199C92.2482 24.7027 93.224 24.9 94.2098 24.9C95.1955 24.9 96.1714 24.7027 97.0798 24.3199C97.9633 23.9492 98.7678 23.4129 99.4498 22.7399C100.123 22.0557 100.662 21.252 101.04 20.3699C101.8 18.532 101.8 16.4678 101.04 14.6299C100.662 13.7478 100.123 12.9441 99.4498 12.2599C98.7663 11.5668 97.9545 11.0132 97.0598 10.6299C96.1514 10.2471 95.1756 10.0498 94.1898 10.0498C93.204 10.0498 92.2282 10.2471 91.3198 10.6299C90.4285 11.0006 89.6201 11.5453 88.9419 12.2321C88.2637 12.9189 87.7292 13.7341 87.3698 14.6299C86.9805 15.5363 86.783 16.5135 86.7898 17.4999" fill="#ffffff" />
            <path id="Vector_8" d="M106.42 17.41C106.413 16.0845 106.674 14.7712 107.19 13.55C107.694 12.3713 108.416 11.299 109.32 10.39C110.231 9.48837 111.303 8.76583 112.48 8.26003C113.704 7.74709 115.018 7.48291 116.345 7.48291C117.672 7.48291 118.986 7.74709 120.21 8.26003C122.591 9.27251 124.488 11.1687 125.5 13.55C126.024 14.769 126.289 16.0833 126.28 17.41V26.48H123.75V24.06L123.35 24.46C122.447 25.3567 121.381 26.0729 120.21 26.57C118.986 27.083 117.672 27.3471 116.345 27.3471C115.018 27.3471 113.704 27.083 112.48 26.57C110.099 25.5575 108.203 23.6614 107.19 21.28C106.675 20.0551 106.413 18.7387 106.42 17.41ZM108.94 17.41C108.938 18.396 109.135 19.3722 109.52 20.28C109.891 21.1771 110.435 21.9921 111.122 22.6785C111.808 23.3649 112.623 23.9091 113.52 24.28C115.358 25.0401 117.422 25.0401 119.26 24.28C120.14 23.9044 120.941 23.3648 121.62 22.69C122.299 22.0184 122.839 21.2199 123.21 20.34C123.547 19.5258 123.744 18.6601 123.79 17.78V17.45C123.797 16.4636 123.599 15.4864 123.21 14.58C122.455 12.772 121.018 11.3347 119.21 10.58C117.853 10.011 116.357 9.85828 114.913 10.1414C113.469 10.4245 112.142 11.1307 111.1 12.17C110.425 12.8488 109.886 13.6497 109.51 14.53C109.125 15.4379 108.928 16.4141 108.93 17.4" fill="#ffffff" />
            <path id="Vector_9" d="M142.49 15.64C142.497 14.8981 142.351 14.1627 142.06 13.48C141.781 12.8189 141.377 12.2178 140.87 11.71C140.364 11.201 139.762 10.7967 139.1 10.52C138.427 10.2299 137.702 10.0801 136.97 10.08C136.231 10.0795 135.501 10.2398 134.83 10.55C134.17 10.8531 133.573 11.277 133.07 11.8C132.562 12.3185 132.152 12.925 131.86 13.59C131.559 14.2315 131.402 14.9313 131.4 15.64V26.48H128.84V8.42001H131.36V10.08C131.632 9.67377 131.97 9.31557 132.36 9.02C132.775 8.6902 133.228 8.41153 133.71 8.19C134.212 7.95693 134.739 7.77922 135.28 7.66C135.829 7.54082 136.388 7.48049 136.95 7.48001C138.03 7.47716 139.099 7.69849 140.09 8.13C141.048 8.55362 141.922 9.14643 142.67 9.88C143.409 10.6307 144.003 11.5124 144.42 12.48C144.85 13.4778 145.072 14.5533 145.07 15.64V26.48H142.47L142.49 15.64Z" fill="#ffffff" />
            <path id="Vector_10" d="M149.861 17.4101C149.854 18.3965 150.052 19.3737 150.441 20.2801C150.807 21.1663 151.343 21.9716 152.021 22.6501C152.705 23.3229 153.509 23.8622 154.391 24.2401C156.229 25.0001 158.293 25.0001 160.131 24.2401C161.013 23.8622 161.817 23.3229 162.501 22.6501C162.99 22.1621 163.401 21.6023 163.721 20.9901H166.501L166.401 21.2801C165.906 22.4609 165.186 23.5342 164.281 24.4401C163.374 25.3461 162.301 26.0692 161.121 26.5701C159.899 27.0849 158.587 27.3502 157.261 27.3502C155.935 27.3502 154.623 27.0849 153.401 26.5701C152.221 26.0692 151.148 25.3461 150.241 24.4401C149.333 23.5346 148.609 22.4614 148.111 21.2801C147.342 19.4591 147.133 17.4499 147.513 15.5098C147.892 13.5697 148.842 11.7871 150.241 10.3901C151.148 9.48408 152.221 8.76095 153.401 8.26007C154.623 7.74522 155.935 7.47998 157.261 7.47998C158.587 7.47998 159.899 7.74522 161.121 8.26007C162.301 8.76095 163.374 9.48408 164.281 10.3901C165.188 11.2941 165.908 12.3678 166.401 13.5501L166.501 13.8001H163.691C163.377 13.2039 162.976 12.6578 162.501 12.1801C161.822 11.5007 161.017 10.9606 160.131 10.5901C159.222 10.2072 158.247 10.01 157.261 10.01C156.275 10.01 155.299 10.2072 154.391 10.5901C153.493 10.9596 152.677 11.5033 151.991 12.1898C151.304 12.8764 150.76 13.6922 150.391 14.5901C150.002 15.4965 149.804 16.4736 149.811 17.4601" fill="#ffffff" />
            <path id="ceja1" d="M103.93 7.92999C103.93 7.92999 103.58 7.75998 102.93 7.48998C102.28 7.21998 101.41 6.82999 100.32 6.42999C99.0917 5.98163 97.8357 5.61417 96.5597 5.32998C95.8537 5.17464 95.1391 5.06111 94.4197 4.98998C93.6845 4.92494 92.9449 4.92494 92.2097 4.98998C91.4878 5.03099 90.7731 5.15514 90.0797 5.35998C89.7297 5.44998 89.4097 5.58999 89.0797 5.70999C88.7497 5.82999 88.4497 6.00998 88.1397 6.15998C87.5719 6.49675 87.0363 6.88506 86.5397 7.31999C86.3097 7.53999 86.0797 7.73998 85.8697 7.96998L85.2797 8.62998C85.0997 8.84998 84.9497 9.06999 84.7897 9.27999C84.6331 9.47368 84.4895 9.67745 84.3597 9.88999C84.1197 10.29 83.8697 10.62 83.7197 10.89L83.1797 11.82C83.1797 11.82 83.2697 11.44 83.4597 10.77C83.7478 9.84827 84.1404 8.96252 84.6297 8.12998L85.1597 7.33999C85.3397 7.05999 85.5897 6.81999 85.8097 6.53999C86.0548 6.27179 86.3152 6.01804 86.5897 5.77999C86.8805 5.53086 87.1843 5.2972 87.4997 5.07998C88.1792 4.65213 88.9122 4.31589 89.6797 4.07998C90.4721 3.82944 91.2909 3.67171 92.1197 3.60998C92.9374 3.56944 93.7571 3.59957 94.5697 3.69999C95.3448 3.80229 96.1106 3.96613 96.8597 4.18998C97.5582 4.38449 98.243 4.62501 98.9097 4.90998C99.5004 5.16006 100.078 5.44044 100.64 5.74999C101.487 6.20203 102.299 6.71679 103.07 7.28999C103.62 7.69999 103.91 7.96998 103.91 7.96998" fill="#ffffff" />
            <path id="ceja2" d="M127.2 11.77C127.2 11.77 127.11 11.36 126.97 10.64C126.83 10.3 126.7 9.90001 126.5 9.45001C126.252 8.96168 125.961 8.4965 125.63 8.06001C125.459 7.80383 125.257 7.56886 125.03 7.36C124.79 7.14 124.59 6.88001 124.32 6.67001L123.49 6.05L122.55 5.51001L122.31 5.38L122.05 5.28001L121.52 5.07001C121.35 5.00001 121.18 4.93001 120.99 4.87001L120.43 4.73001C119.674 4.53072 118.901 4.40677 118.12 4.36H116.93C116.734 4.35001 116.537 4.35001 116.34 4.36L115.75 4.43001C115.36 4.49001 114.97 4.53001 114.59 4.57001L113.46 4.83C112.747 4.98548 112.046 5.18928 111.36 5.44C111.02 5.55 110.69 5.64001 110.36 5.76001L109.48 6.13C108.91 6.39 108.36 6.56 107.92 6.8L106.74 7.38C106.08 7.70001 105.74 7.87001 105.74 7.87001C105.74 7.87001 106.06 7.60001 106.64 7.15001L107.7 6.34001C108.176 6.00573 108.67 5.69857 109.18 5.42001L110.03 4.92001C110.34 4.75001 110.67 4.63 111.03 4.47L112.03 4.02C112.397 3.86835 112.775 3.74467 113.16 3.65001L114.34 3.3L115.6 3.10001L116.24 3.01001C116.46 3.01001 116.67 3.01001 116.89 3.01001H118.21C119.086 3.0854 119.953 3.24275 120.8 3.48001L121.43 3.66C121.63 3.73 121.82 3.83001 122.02 3.92001L122.6 4.18001L122.89 4.32001L123.15 4.49001C123.5 4.72001 123.85 4.93001 124.15 5.17001L125.01 5.96001C125.269 6.2265 125.51 6.51051 125.73 6.81001C125.945 7.07558 126.129 7.36399 126.28 7.67001C126.584 8.18379 126.819 8.73514 126.98 9.31001C127.095 9.75369 127.178 10.2048 127.23 10.66C127.23 11.39 127.23 11.81 127.23 11.81" fill="#ffffff" />
            <path id="Vector_13" d="M170.06 17.4C170.056 18.3852 170.25 19.3612 170.63 20.27C171.006 21.1503 171.546 21.9512 172.22 22.63C173.611 24.0174 175.496 24.7965 177.46 24.7965C179.425 24.7965 181.309 24.0174 182.7 22.63C183.189 22.142 183.601 21.5822 183.92 20.97H186.7L186.6 21.26C186.108 22.4422 185.387 23.516 184.48 24.42C183.573 25.326 182.5 26.0491 181.32 26.55C180.096 27.0629 178.782 27.3271 177.455 27.3271C176.128 27.3271 174.814 27.0629 173.59 26.55C172.415 26.0465 171.346 25.3236 170.44 24.42C169.53 23.5164 168.806 22.4427 168.31 21.26C167.797 20.036 167.533 18.7221 167.533 17.395C167.533 16.0679 167.797 14.754 168.31 13.53C168.806 12.3473 169.53 11.2736 170.44 10.37C171.346 9.46639 172.415 8.7435 173.59 8.23999C174.814 7.72705 176.128 7.46289 177.455 7.46289C178.782 7.46289 180.096 7.72705 181.32 8.23999C182.5 8.74087 183.573 9.464 184.48 10.37C185.387 11.274 186.108 12.3478 186.6 13.53C187.03 14.515 187.284 15.5675 187.35 16.64H170.06C170.06 16.9 170.06 17.15 170.06 17.4ZM182.7 12.16C182.019 11.4839 181.214 10.9443 180.33 10.57C179.422 10.1872 178.446 9.98993 177.46 9.98993C176.475 9.98993 175.499 10.1872 174.59 10.57C173.706 10.9443 172.902 11.4839 172.22 12.16C171.65 12.7475 171.177 13.4228 170.82 14.16H184.06C183.707 13.4281 183.245 12.7539 182.69 12.16" fill="#ffffff" />
            <path id="Vector_14" d="M175.609 4.89L179.029 0H183.079L177.819 4.89H175.609Z" fill="#ffffff" />
          </g>
          <defs>
            <clipPath id="clip0_771_864">
              <rect width="187.3" height="33.66" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div class="text-white">
          <p class="mt-4 fs-5 fw-light">Cargando artículo
            <span class="animate_dot0">.</span>
            <span class="animate_dot">.</span>
            <span class="animate_dot2">.</span>
          </p>
        </div>
      </div>
    </div>
  )
}
